<template>
  <div>
    <NavigationBar name="成员审核"> </NavigationBar>
    <div class="content">
      <div class="info-content margin-top">
        <div class="flexbox">
          <p class="text-title">邀请ID</p>
          <p>{{ detail.id }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">状态</p>
          <p>{{ detail.user_status | userStatus }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">邀请时间</p>
          <p>{{ detail.create_time }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">姓名</p>
          <p>{{ detail.name }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">手机号</p>
          <p>{{ detail.mobile }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">微信号</p>
          <p>{{ detail.wechat }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">邮箱</p>
          <p>{{ detail.user_email }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">身份证</p>
          <p>{{ detail.idcard }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">职称</p>
          <p>{{ detail.zhicheng }}</p>
        </div>
        <div class="flexbox margin-top">
          <p class="text-title">职位</p>
          <p>{{ detail.zhiwei }}</p>
        </div>
      </div>
    </div>
    <md-action-bar v-show="detail.user_status == 2" :actions="actionsData"></md-action-bar>

  </div>

</template>

<script>
    export default {
        name: "inviteDetail",
        data() {
            return {
                id: 0,
              actionsData:[ {
                text: '不同意',
                onClick: this.handleClick,
                status:0
              },
                {
                  text: '同意',
                  onClick: this.handleClick,
                  status:1
                }],
                detail:{
                  user_status :2
                }
            }
        },
      filters: {
        userStatus(data) {
          switch (data) {
            case 1:
              return '审核通过'
            case 2:
              return '待审核'
            case 0:
              return '不通过'
            default :
              return data
          }
        }
      },
        mounted() {
          this.id = this.$route.query.id
          this.getData()
        },
      methods: {

        handleClick(event, action){
          console.log(action)
          this.$API.register.approve(this.id,action.status).then(res=>{
              this.$toast.succeed('操作成功')
              setTimeout(()=>this.$router.go(-1))
          })
        },
            getData(){
                this.$API.register.userRegisterDetail(this.id).then(res=>{
                  console.log(res)
                  this.detail = res.data
                })
            }
        }
    }
</script>

<style scoped>
.content {
    padding:0 20px;
}
.info-content {
    font-size: 33px;
}
.info-content div:not(:first-child) p {
    color: rgb(146, 146, 146);
}
.flexbox {
    display: flex;
    justify-content: space-between;
}
.text-title {
    width: 160px;
    text-align: center;
}
.margin-top{
    margin-top: 70px;
}
</style>
